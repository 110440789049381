.root {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
}

.window {
  width: 100%;
  max-width: 580px;
  border: 1px solid currentColor;
  border-radius: 3px;
  background: black;
}

@media(min-height: 800px) {
  .window {
    transform: translateY(-50%);
  }
}

.inner {
  padding: 15px;
  text-align: center;
}

@media(min-height: 800px) {
  .inner {
    padding: 20px;
  }
}

.inner a {
  color: inherit;
  text-decoration: underline;
}

.buttons {
  display: flex;
  margin: 20px 0 0;
  flex-wrap: wrap;
}

.buttons_single {
  justify-content: center;
}

.buttons_double {
  justify-content: space-between;
}

.buttons button {
  flex: 0 0 100%;
  margin-bottom: 15px;
  text-align: center;
}

.buttons button:last-child {
  margin: 0;
}

.buttons button.continue {
  font-weight: bold;
}

@media(min-width: 800px) {
  .buttons button {
    flex: 0 0 calc(50% - 7.5px);
    margin: 0;
  }

  .buttons button.continue {
    font-weight: bold;
    order: 2;
  }
}

