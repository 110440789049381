:root {
  --spacing-sm: 15px;
  --spacing-lg: 20px;
}

.root {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  height: 100%;
  margin: auto;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 14px;
  line-height: 1.4;
  color: #efefef;
  
  * {
    box-sizing: border-box;
  }
}

.root p {
  margin: 0 0 1em;
}

.root p:last-child {
  margin: 0;
}

.form {
  padding: calc(var(--spacing-sm) * 1) calc(var(--spacing-sm) * 0.5) 0;
}

.form ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.form li {
  flex: 0 0 50%;
  padding: 0 calc(var(--spacing-sm) * 0.5) calc(var(--spacing-sm) * 1);
  box-sizing: border-box;
}

.form li.username {
  flex: 1;
}

.form li.folder {
  flex: 0 0 100%;
}

.form li.submit,
.form li.reset {
  flex: 0;
}

@media (min-width: 800px) {
  .form {
    padding: calc(var(--spacing-lg) * 1) calc(var(--spacing-lg) * 0.5) 0;
  }

  .form ul {
    flex-wrap: nowrap;
  }

  .form li {
    flex: 1;
    padding: 0 calc(var(--spacing-lg) * 0.5) calc(var(--spacing-lg) * 1);
  }

  .form li.username {
    flex: 0;
  }

  .form li.username input {
    width: auto;
  }

  .form li.folder {
    flex: 1;
  }

  .form li.reset {
    order: 99;
  }
}

input,
select,
button {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 40px; 
  margin: 0;
  padding: 0 var(--spacing-sm);
  background-color: transparent !important;
  border: 1px solid currentColor;
  border-radius: 3px;
  color: currentColor;
  font-size: 16px;
  font-family: inherit;
  text-align: left;
  white-space: nowrap;
}

select {
  appearance: none;
}

button {
  margin: 0;
}

@media (min-width: 800px) {
  input,
  select {
    width: 100%;
  }
}

.output {
  flex: 1;
  position: relative;
}

.message {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  pointer-events: none;
}

.message div {
  padding: var(--spacing-sm);
  background: rgba(0, 0, 0, 0.75);
}

@media (min-width: 800px) {
  .message div {
    padding: var(--spacing-sm) var(--spacing-lg);
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 var(--spacing-sm);
}

@media (min-width: 800px) {
  .image {
    padding: 0 var(--spacing-lg);
  }
}

.canvas {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: opacity 1s;
  cursor: pointer;
}

.canvas:not([data-complete="true"]) {
  opacity: 0.5;
  pointer-events: none;
}

.loading {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 50px;
    height: 50px;
  }
}

.footer {
  padding: var(--spacing-sm);
  text-align: center;
}

.footer span {
  display: block;
  margin: 0 0.25em;
}

.footer a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.75);
}

@media (min-width: 800px) {
  .footer {
    padding: var(--spacing-lg);
  }

  .footer span {
    display: inline;
  }
}
